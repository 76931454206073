export default {
  'gradient-radial': 'radial-gradient(var(--tw-gradient-stops))',
  'gradient-find-influencers': `linear-gradient(180deg, white 0%, rgba(255, 255, 255, 0.4) 40%, rgba(255, 255, 255, 0.1) 100%),
    radial-gradient(farthest-corner at 0% 60%, #94b4f6 16.34%, rgb(148, 180, 246, 0) 50.08%),
    radial-gradient(farthest-corner at 100% 85%, #94b4f6 0.34%, rgb(148, 180, 246, 0) 30.08%),
    radial-gradient(ellipse farthest-corner at 20% -5%, rgb(75, 254, 133, 0.8) 10.34%, rgb(75, 254, 133, 0) 40.08%),
    linear-gradient(#865cff 25.23%, #94b4f6 70.91%, rgb(75, 254, 133, 0.7) 97%)`,
  'gradient-marquee':
    'linear-gradient(86deg,#89ffbf 10.26%,#a4fa84 53.53%,#b3a7ff 100%)',
  'recommended-price': `linear-gradient( 271.32deg, #86BEDC -0.41%, rgba(134, 190, 220, 0) 25.66%),
    linear-gradient( 113.54deg, rgba(188, 223, 123, 0.6) 29.38%, rgba(121, 223, 255, 0.6) 85.33%),
    linear-gradient( 111.34deg, #03FFCA 0%, #8DF79D 100%)`,
  'gradient-radial-to-tr':
    'radial-gradient(115% 90% at 0% 100%, var(--tw-gradient-stops))',
  'gradient-radial-to-tl':
    'radial-gradient(115% 90% at 100% 100%, var(--tw-gradient-stops))',
  'gradient-radial-to-br':
    'radial-gradient(90% 115% at 0% 0%, var(--tw-gradient-stops))',
  'gradient-radial-to-bl':
    'radial-gradient(90% 115% at 100% 0%, var(--tw-gradient-stops))',
  'gradient-radial-from-center':
    'radial-gradient(circle at center, var(--tw-gradient-stops))',
  'grid__devMode-4': 'var(--grid__devMode-4)',
  'grid__devMode-8': 'var(--grid__devMode-8)',
  'grid__devMode-12-md': 'var(--grid__devMode-12-md)',
  'grid__devMode-12-lg': 'var(--grid__devMode-12-lg)',
  shine:
    "url(\"data:image/svg+xml,%3Csvg width='165' height='155' viewBox='0 0 165 155' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M165 -6.10352e-05H65C65 -6.10352e-05 37.1135 5.19052 20 33C4 59 21 68.5 38 98.5C51.6 122.5 17.3333 147.333 0 155H100C100 155 126 143 137.5 123.5C155.875 92.3417 104.5 70 115.5 42.5C126.5 15 165 -6.10352e-05 165 -6.10352e-05Z' fill='white'/%3E%3C/svg%3E\")",
  'gradient-btn':
    'linear-gradient( 90deg, #8ea6fd 2.78%, #b1e7fc 61.52%, #5ecdf8 86.83%)',
  'gradient-find-brands': `linear-gradient(180deg, white 0%, rgba(255, 255, 255, 0) 40%),
    radial-gradient(farthest-corner at 100% 90%, #79dfff 16.34%, rgb(208, 242, 36, 0.5) 50%),
    radial-gradient(farthest-corner at 5% 80%, #79dfff 16.34%, rgb(208, 242, 36, 0.3) 54.08%)`,
  'gradient-gallery':
    'linear-gradient(to bottom, #7effb8, #71ffad, #64ffa0, #58ff93, #4bfe85)',
  'gradient-selling-marquee':
    'linear-gradient( 90deg, #daffe6 20.11%, #b8ffcf 40.41%, #4bfe85 88.49%)',
  'homepage-merchant-testimonial':
    'linear-gradient(-10deg, #00ff75 15%, #cec9f8 50%, #161818 85%)',
  'card-with-mobile-frame':
    "url('https://cdn.shopify.com/b/shopify-brochure2-assets/46f52f152636dc37742a89f16b198093.png')",

  // /starter
  'starter-main': `radial-gradient(
      167.79% 66.3% at 50% 50%,
      rgba(136, 248, 181, 0.5) 0%,
      rgba(173, 244, 209, 0.5) 25%,
      rgba(213, 247, 244, 0.5) 44.79%,
      rgba(231, 251, 242, 0.5) 57.29%,
      rgba(255, 255, 255, 0.5) 70.83%
    )`,

  // /protect route
  'protect-hero': `radial-gradient(circle at 0% 0%, #f4f4ed, #f4f4ed00 30%),
    radial-gradient(circle at 0% 100%, #f4f4ed, #f4f4ed00 50%),
    radial-gradient(circle at 45% 0%, #f4f4ed, #f4f4ed00 15%),
    radial-gradient(ellipse at 100% 0%, #f4f4ed, #8bffc100 35%),
    radial-gradient(circle at 10% 80%, #f4f4ed, #f4f4ed00 50%),
    radial-gradient(circle at 20% 20%, #8bffc1, #8bffc100 40%),
    radial-gradient(circle at 30% 40%, #8bffc1, #8bffc100 30%),
    radial-gradient(circle at 60% 0%, #8bffc1, #8bffc100 70%),
    radial-gradient(circle at 70% 0%, #8bffc1, #8bffc100 50%),
    radial-gradient(circle at 50% 50%, #f4f4ed, #f4f4ed00 50%),
    radial-gradient(
      circle farthest-side at 100% 100%,
      #5433eb,
      #5433eb00 100%
    )`,
  'protect-conversion': `radial-gradient(circle at 0% 0%, #f4f4ed, #f4f4ed00 30%),
    radial-gradient(circle at 0% 100%, #f4f4ed, #f4f4ed00 50%),
    radial-gradient(circle at 45% 0%, #f4f4ed, #f4f4ed00 15%),
    radial-gradient(ellipse at 100% 0%, #f4f4ed, #8bffc100 35%),
    radial-gradient(circle at 10% 80%, #f4f4ed, #f4f4ed00 50%),
    radial-gradient(circle at 20% 20%, #8bffc1, #8bffc100 40%),
    radial-gradient(circle at 30% 40%, #8bffc1, #8bffc100 30%),
    radial-gradient(circle at 60% 0%, #8bffc1, #8bffc100 70%),
    radial-gradient(circle at 70% 0%, #8bffc1, #8bffc100 50%),
    radial-gradient(circle at 50% 50%, #f4f4ed, #f4f4ed00 50%),
    radial-gradient(
      circle farthest-side at 100% 100%,
      #5433eb,
      #5433eb00 100%
    )`,

  // /pre-orders route
  'pre-orders-conversion':
    'linear-gradient(to bottom right, #cec9f8, #cec9f8 40%, #d0f224)',

  // /youtube route
  'youtube-before':
    'linear-gradient(157.79deg, #e5fbba 28.65%, #79dfff 83.72%)',

  // /walmart route
  'walmart-hero':
    'linear-gradient(170deg, rgba(0, 0, 0, 0) 70%, #fcf3b0), linear-gradient(143.92deg, #c6f0fc 34.39%, #fcf3b0 89.64%)',
  'walmart-conversion':
    'linear-gradient(120.58deg, #c6f0fc 0%, #fdf4b1 113.94%)',

  // /shop-pay route
  'shop-pay-hero': 'linear-gradient(180deg, #ffffff 12.5%, #e6ede2 100%)',
  'shop-pay-sideBySideList':
    'linear-gradient(180deg, #ffffff 47.66%, #f0edff 100%)',
  'shop-pay-checkout-links':
    'linear-gradient(180deg, #f0edff 0%, #e1dbfe 100%)',
  'shop-pay-carbon-footprint':
    'linear-gradient(180deg, #e1dbfe 12.5%, #ffffff 100%)',
  'shop-pay-stop-fraud':
    'radial-gradient(circle at 50% 100%, #f7f7f3 3%, #c2fad9 25%, #c2fad900 50%), radial-gradient(circle at 100% 100%, #f7f7f3 3%, #c2fad9 10%, #c2fad900 20%), radial-gradient(circle at 100% 0%, #958cf2, #958cf200 40%)',

  // /shop-promise route
  'shop-promise-hero': 'linear-gradient(180deg, #FFFFFF 0%, #E2DDF8 73.75%)',
  'shop-promise-conversion': `url('https://cdn.shopify.com/shopifycloud/brochure2/production/images/shop-promise/shop-promise-bg.jpg')`,

  // /shipping/local... routes
  'shipping-local-hero':
    'linear-gradient(168.94deg, #000000 50.92%, #c255cb 75.45%, #ff42a2 89.34%, #ff3008 98.7%)',
  'shipping-local-hero-700':
    'linear-gradient(168deg, #000000 63.92%, #c255cb 77.45%, #ff42a2 89.34%, #ff3008 96%)',
  'shipping-local-conversion':
    'linear-gradient(264.2deg, rgba(255, 48, 8, 0.8) -13.99%, rgba(237, 107, 248, 0.736) 36.83%, rgba(121, 223, 255, 0.8) 133.57%)',

  // /tax route
  'tax-hero':
    'linear-gradient(354.7deg, #ffffff -6.48%, #ffffff00 34.59%), linear-gradient(236.78deg, #fcf3b0 0.15%, #fcf3b000 49.35%), linear-gradient(162.7deg, #ed6bf8 -25%, #cec9f8 66.46%)',
  'tax-conversion':
    'linear-gradient(102.07deg, #cec9f8 34.61%, #ed6bf8 121.14%)',

  // homepage
  'homepage-hero-before': `radial-gradient(100% 68% at 70% 15%, transparent 40%, white 79%),
    conic-gradient(
        from 90deg at 1px 1px,
        rgba(0, 0, 0, 0) 90deg,
        rgba(202, 212, 215, 0.2) 0
      )`,
  'homepage-hero-after':
    'linear-gradient(203deg, rgba(132, 174, 241, 0.867) 8.4%, rgba(38, 238, 159, 0.47) 30.67%, rgba(21, 188, 223, 0.333) 70.04%, rgba(255, 255, 255, 0) 85%);',
  'homepage-hero-form-wrapper':
    'linear-gradient(90deg, #c8dee4 0%, rgba(169, 211, 225, 0) 100%)',
  'homepage-editions-border':
    'linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(27, 28, 28, 0) 100%)',
  'homepage-editions-container-after':
    'linear-gradient(180deg, rgba(242, 239, 243, 0) -0.55%, rgba(33, 162, 255, 0.7) 50.81%, rgba(239, 241, 243, 0) 101.11%)',
  'homepage-editions-container-before':
    'linear-gradient(90deg, rgba(239, 241, 243, 0) -0.55%, rgba(33, 162, 255, 0.7) 50.81%, rgba(239, 241, 243, 0) 101.11%)',
  'homepage-editions-heading-span':
    'linear-gradient(90.48deg, #42bafd 48.6%, #d335df 100.55%),linear-gradient(0deg, #ffffff, #ffffff)',
  'homepage-globe-headline-before':
    'linear-gradient(90deg, #e9f9f4 0%, #cfebfe 45.31%, #f2eefe 100%)',
  'homepage-merchant-obsessed-container':
    'radial-gradient(100% 70% at 50% 50%, rgba(233, 249, 244, 0.8) 10.92%, rgba(207, 235, 254, 0.8) 31.75%, rgba(242, 238, 254, 0.8) 57.04%, transparent 80%)',
  'homepage-merchant-obsessed-headline':
    'linear-gradient(106.94deg, #15c064 28.5%, #00d1ff 91.82%)',
  'homepage-promo-bar':
    'linear-gradient(89.4deg, #FFFFFF -31.43%, #C8ECFF 53.03%, #F3EEFF 138.89%)',

  // /apple-pay
  'apple-pay-logo': `url('https://cdn.shopify.com/shopifycloud/brochure2/production/images/apple-pay/apple-pay-logo.svg')`,

  // /balance
  'balance-outcomes':
    'linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #dce2f5 100%)',
  'balance-hero':
    'linear-gradient(188deg, #EBFFC6 -100%, rgba(255, 255, 255, 0.00) 100%)',
  'balance-conversion': 'linear-gradient(180deg, #FFF 0%, #EEFAB3 100%)',

  // /collabs/flow
  'collabs-flow-conversion':
    'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(43.5% 136.66% at 32.01% 32.65%, #AB8EFF 50.64%, rgba(171, 142, 255, 0) 100%), linear-gradient(99.64deg, #94B4F6 34.47%, #3ADBA6 74.65%)',
  'collabs-flow-hero':
    'linear-gradient(0deg, #FFFFFF -0.07%, rgba(255, 255, 255, 0) 77.43%), radial-gradient(66.24% 89.22% at 44.17% 31.78%, #AB8EFF 0%, rgba(171, 142, 255, 0) 100%), linear-gradient(226.06deg, #94B4F6 45.07%, #3ADBA6 77.89%)',

  // /collabs/case-studies/brands
  'collabs-case-studies-conversion':
    'linear-gradient(121.04deg, #84C6C5 15.69%, #865CFF 100%)',

  // /free-trial
  'free-trial-conversion':
    'radial-gradient(circle at 0% 0%, #5FD5B6 0%, rgba(95, 213, 182, 0) 30%), linear-gradient(to bottom, rgba(121, 223, 255, .7) -10%, rgba(121, 223, 255, 0) 70%), radial-gradient(circle at 95% 0%, #D0F224 0%, rgba(95, 213, 182, 0) 20%)',
  'free-trial-hero':
    'linear-gradient(0deg, #F1FFF9 0%, rgba(241, 255, 249, 0.00) 30%),radial-gradient(circle at 0 0,#5FD5B6 0,rgba(95,213,182,0) 30%),linear-gradient(180deg,rgba(121,223,255,.7) -10%,rgba(121,223,255,0) 70%),radial-gradient(circle at 95% 0,#D0F224 0,rgba(95,213,182,0) 20%)',

  // /free-trial/creators
  'free-trial-creators-main':
    'linear-gradient(180deg, #dcfbbe 0%, #f3fffd 44.48%, #ffffff 73.97%)',
  'free-trial-creators-conversion':
    'linear-gradient(360deg, #d5fff8 2.95%, #e3ffca 96.77%)',

  // /google-pay
  'google-pay-hero': `url('https://cdn.shopify.com/shopifycloud/brochure2/production/images/google-pay/google-pay-logo.svg')`,

  // /markets
  'markets-hero-before':
    'linear-gradient(rgba(6, 25, 79, 0.6), transparent), linear-gradient(to top left, rgb(0, 0, 0), transparent), linear-gradient(to top right, rgb(8, 58, 54), transparent)',
  'markets-hero-after': `url('https://cdn.shopify.com/shopifycloud/brochure2/production/images/markets/hero-background-globe.webp')`,
  'markets-hero-button': 'linear-gradient(to right, #69cf9c, #d0f224)',
  'markets-hero-button-hover': 'linear-gradient(#69cf9c, #69cf9c)',
  'markets-conversion-first':
    'linear-gradient(113deg, #69cf9c 70%, #79dfff 95%)',
  'markets-conversion-button': 'linear-gradient(to right, #69cf9c, #d0f224)',
  'markets-conversion-button-focus': 'linear-gradient(#d0f224, #d0f224)',
  'markets-product-demo-3':
    'linear-gradient(180deg, #dbf7ff 0%, #f2fffa 76.04%)',
  'markets-pill-pro': 'linear-gradient(to top left, #79dfff, #a5e3b9)',
  'markets-pill-pro-before': `url('https://cdn.shopify.com/shopifycloud/brochure2/production/images/markets/markets-icon.svg')`,
  'markets-pro-demo-hero': `linear-gradient(0deg, rgba(255, 255, 255) 20.0%, rgba(252, 222, 207, 0.50) 54%, rgba(200, 238, 237, 0.70) 98%)`,
  'markets-pro-demo-conversion': `url('https://cdn.shopify.com/b/shopify-brochure2-assets/f76a0a30ebf756138781d1f55637d058.jpg')`,

  // /online
  'online-hero':
    'linear-gradient(76.81deg, #79dfff 0%, #a6b2fc 59.9%, #ed6bf8 100%)',

  // /payment-gateways
  'payment-gateways-conversion':
    'linear-gradient(99.28deg, #C9B1FC 0%, #94E6FF 48.44%, #71FEA1 100%)',

  // /pos
  'pos-hero': `radial-gradient(ellipse at 0% 10%, #001e21, #001e2100 40%),
    radial-gradient(ellipse at 0% 10%, #022224, #02222400 40%),
    radial-gradient(ellipse at 80% 20%, #023433, #02343300 40%),
    radial-gradient(ellipse at 40% 30%, #003b4d, #003b4d00 60%),
    radial-gradient(ellipse at 40% 30%, #004358, #00435800 40%),
    radial-gradient(ellipse at 10% 10%, #054a49, #054a49 60%)`,

  // /pos overview redesign
  'pos-overview-hero-base': `linear-gradient(0deg, #f3f3ed00 35%, #f3f3edff 60%)`,
  'pos-overview-hero-sm': `linear-gradient(0deg, #f3f3ed00 40%, #f3f3edff 70%)`,
  'pos-overview-hero-lg': `linear-gradient(0deg, #f3f3ed00 60%, #f3f3edff 90%)`,

  // /pos/features
  'pos-features-hero': `radial-gradient(ellipse at 0% 0%, #003b4d, #003b4d00 60%),
    radial-gradient(ellipse at 20% 10%, #003b4d, #003b4d00 40%),
    radial-gradient(ellipse at 70% 40%, #054a49, #054a4900 60%),
    radial-gradient(ellipse at 80% 20%, #054a49, #054a4900 40%),
    radial-gradient(ellipse at 100% 0%, #054a49, #054a4900 70%),
    linear-gradient(to bottom, var(--tw-gradient-stops))`,

  // /pos/hardware-next
  'pos-hardware-hero':
    'linear-gradient(0deg, var(--tw-gradient-from) 450px, var(--tw-gradient-to) 700px)',

  // /pos-go
  'pos-go-conversion-first':
    'radial-gradient(100% 100% at 50% 0%, rgba(219, 255, 0, 0.7) 0%, rgba(238, 250, 179, 0) 100%)',
  'pos-go-conversion-last':
    'linear-gradient(180deg, #4d5349 0%, rgba(77, 83, 73, 0) 100%)',

  // /pos/meet-pos-go
  'meet-pos-go':
    'linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 85.79%, #4d5349)',

  // /pos/pop-up-sales
  'pos-pop-up-sales-hero': 'linear-gradient(180deg, #054a49 0%, #1e7372 100%)',

  // /pos/pricing
  'pos-pricing-hero':
    'radial-gradient(ellipse at 100% 0%, #054a49 50%, #1e7372 100%)',
  'pos-pricing-conversion':
    'linear-gradient(286.11deg, #5fd5b6 -4.86%, #fcf3b0 113.97%)',

  // /pos/pricing-next
  'pos-pricing-next-hero-before': `radial-gradient(55% 40% at 125% 50%, #eefab3, transparent),
  radial-gradient(65% 40% at -35% 40%, #d0f224, transparent),
  radial-gradient(70% 25% at 0% 0%, #eefab3, transparent),
  radial-gradient(50% 30% at 100% 0%, #eefab3, transparent),
  radial-gradient(30% 30% at 110% 10%, #d0f224, transparent)`,

  'pos-pricing-next-hero-before-md': `radial-gradient(55% 40% at 125% 28%, #d0f224, transparent),
  radial-gradient(65% 40% at -35% 28%, #d0f224, transparent),
  radial-gradient(70% 25% at 0% 0%, #eefab3, transparent),
  radial-gradient(50% 30% at 100% 0%, #eefab3, transparent),
  radial-gradient(30% 30% at 110% 10%, #d0f224, transparent)`,

  // /pos/pricing POS Pro vs Lite
  'pos-pricing-next-outcomes-expand': `radial-gradient(50% 80% at 0 70%, rgba(77, 93, 1, .4), transparent),
  radial-gradient(50% 50% at 105% 55%, rgba(77, 93, 1, .5), transparent)`,

  'pos-pricing-next-outcomes-expand-md': `radial-gradient(50% 30% at 0% 50%, rgba(77,93,1, .4), transparent),
  radial-gradient(80% 30% at 105% 50%, rgba(77, 93, 1, .5), transparent)`,

  // /pos/pricing POS Pro vs Lite
  'pos-pricing-next-pro-vs-lite': `radial-gradient(60% 30% at 0% 0%, rgba(77, 93, 1, .4), transparent),
  radial-gradient(30% 60% at 100% 0%, rgba(77, 93, 1, .4), transparent),
  radial-gradient(60% 20% at 0% 35%, rgba(33, 33, 33, 1), transparent)`,

  'pos-recommended-price': `linear-gradient(90deg, #4D5D01 0%, #799102 100%)`,

  // /pos/pricing-next pos pro component
  'pos-pricing-next-pos-pro-before': `radial-gradient(80% 45% at 130% 50%, #eefab3, transparent),
  radial-gradient(80% 35% at -25% 45%, #eefab3, transparent),
  radial-gradient(40% 15% at 35% 40%, #eefab3, transparent),
  radial-gradient(40% 15% at 70% 40%, #eefab3, transparent);`,

  // /introducing-credit perks section
  'introducing-credit-perks': `radial-gradient(30% 45% at 0% 45%, #A5E3B966, transparent),
    radial-gradient(30% 45% at 110% 55%, #D0F22444, transparent);`,

  // /introducing-credit marquee section
  'introducing-credit-marquee': `radial-gradient(40% 25% at 40% 60%, #A5E3B928, transparent),
    radial-gradient(40% 25% at 50% 50%, #D0F22428, transparent);`,

  // /pricing
  'pricing-hero': `radial-gradient(circle at 56% 148%, #e1f0ff 5%, transparent 48%),
    radial-gradient(circle at 151% -176%, #006AE8 7%, #e1f0ff 58%, #fafdff 71%, transparent 82%),
    radial-gradient(circle at -26% 182%, #26EE9F 0%, transparent 54%),
    radial-gradient(circle at -28% -48%, #30E9BE 0%, transparent 38%)`,
  'pricing-hero-before': `radial-gradient(circle at 137% 20%, #15bcdf 15%, transparent 35%),
    radial-gradient(circle at 105% 50%, #00ffb2 0%, transparent 30%),
    radial-gradient(circle at 5% -20%, #00ffb2 0%, transparent 20%)`,
  'pricing-hero-next': `linear-gradient(205deg, rgba(21,188,223,0.3) 0%, rgba(21,188,223,0.05) 50%, rgba(48,233,190,0.3) 100%)`,
  'pricing-conversion-last': `linear-gradient(60deg, #15bcdf 5%, #28efcd 31%, #0bdb77 60%, transparent 95%), linear-gradient(300deg, #28efcd 35%, transparent 70%)`,
  'pricing-conversion-last-2': `linear-gradient(96deg, #79DFFF -52.01%, #4BFE85 100%)`,

  // /sell-on-tiktok
  'sell-on-tiktok-hero':
    'radial-gradient(61% 38% at 45% 95%, #61fffa, transparent), radial-gradient(64% 80% at 46% 66%, #c6f0fc, transparent), radial-gradient(40% 60% at 84% 54%, #ff0a3b, transparent);',
  'sell-on-tiktok-conversion':
    'radial-gradient(66% 57% at 73% 28%, #61fffa, transparent), radial-gradient(99% 100% at 15% 12%, #c6f0fc, transparent), radial-gradient(97% 42% at 75% 96%, #ff0a3b, transparent);',

  // /shop-pay-installments
  'shop-pay-installments-conversion':
    'linear-gradient(#fcdecf 10%, #ead3da, #d2c5e7)',
  'shop-pay-installments-testimonials':
    'linear-gradient(100.68deg, #c6f0fc 28.02%, #a9acff 107.94%)',
  'shop-pay-installments-cards': 'linear-gradient(#d2c5e7 70%, #c5beef)',
  'shop-pay-installments-disclaimers': 'linear-gradient(#c5beef, #c3bdf0)',

  // tokengated-commcerce route
  'tgc-hero-gradient': `linear-gradient(to bottom, transparent 50%, white), url("https://cdn.shopify.com/shopifycloud/brochure2/production/images/tokengated-commerce/grid.svg")`,
  'tgc-conversion-gradient': `url("https://cdn.shopify.com/shopifycloud/brochure2/production/images/tokengated-commerce/grid.svg")`,
  'tgc-shared-gradient':
    'linear-gradient(358deg, #79dfff, #7de6f8, #79dfff, #a9e0fc, #79dfff)',

  // /capital
  'capital-outcomes':
    'radial-gradient(137.93% 48.07% at 0% 48.9%, #E8E2D0 0%, rgba(255, 255, 255, 0) 75%)',
  'capital-conversion':
    'linear-gradient(182.81deg, #FFFFFF 42.33%, #A5E3B9 99.47%);',

  // /capital-new-year-2023 route
  'capital-new-year-2023-outcomes':
    'linear-gradient(180deg, #ffffff 23.96%, #c5d1ff 100%)',
  'capital-new-year-2023-conversion':
    'linear-gradient(180deg, #FFFFFF 0%, #A5E3B9 100%)',

  // /collabs/find-influencers
  'collabs-merchants-hero':
    'linear-gradient(174.27deg, #918ef3 18.35%, #c5d1ff 80.83%);',
  'collabs-merchants-conversion':
    'linear-gradient(180.39deg, #918ef3 12.58%, #c5d1ff 99.65%)',

  // /collective/retailer
  'collective-hero':
    'radial-gradient(90% 100% at top left, #CEC9F8 60%, #ffffff 100%)',
  'collective-supplier-hero':
    'radial-gradient(90% 100% at top left, #C1FBD4 60%, #ffffff 100%)',
  'collective-section-2':
    'radial-gradient(100% 80% at right, #C1FBD4 30%, #ffffff 60%);',
  'collective-supplier-section-2':
    'radial-gradient(100% 80% at right, #CEC9F8 30%, #ffffff 60%);',
  'collective-section-2-sm':
    'radial-gradient(30% 60% at right, #C1FBD4 15%, #ffffff 60%)',
  'collective-supplier-section-2-sm':
    'radial-gradient(30% 60% at right, #CEC9F8 15%, #ffffff 60%)',
  'collective-section-2-md':
    'radial-gradient(70% 160% at bottom right, #C1FBD4 30%, #ffffff 60%)',
  'collective-supplier-section-2-md':
    'radial-gradient(70% 160% at bottom right, #CEC9F8 30%, #ffffff 60%)',
  'collective-section-2-images':
    'linear-gradient(180deg, #FFFFFF 0.20%, rgba(255, 255, 255, 0) 20%)',
  'collective-dual-conversion':
    'linear-gradient(97.45deg, #CEC9F8 0%, #4BFE85 98.43%)',

  // /sell/... routes
  'sell-value-prop-mobile':
    'linear-gradient(180deg, #fbf8f7 0, #fbf8f7 80%, #fff 80%, #fff)',
  'sell-value-prop-desktop':
    'linear-gradient(to left, #fbf8f7 0, #fbf8f7 72%, #fff 72%, #fff)',
  'sell-value-prop-alt-mobile':
    'linear-gradient(180deg, #f4f7f6 0, #f4f7f6 80%, #fff 80%, #fff)',
  'sell-value-prop-alt-desktop':
    'linear-gradient(to right, #f4f7f6 0, #f4f7f6 83%, #fff 83%, #fff)',

  // /paypal
  'paypal-logo': `url('https://cdn.shopify.com/shopifycloud/brochure2/production/images/paypal/paypal-logo.svg')`,
  'paypal-outcomes':
    'radial-gradient(circle at 78% 50%, #a9acff99, #ffffff 24%)',

  // /collabs/find-brands
  'collabs-creators-header-button':
    'linear-gradient(270deg, #79dfff 8.85%, #d0f224 78.65%)',
  'collabs-creators-hero-before':
    'linear-gradient(227.15deg, #79dfff 28.14%, #D0F224 89.18%)',
  'collabs-creators-hero-after':
    'linear-gradient(rgba(238, 250, 179, 0) 0%, #eefab3 97%);',
  'collabs-creators-conversion':
    'url(https://cdn.shopify.com/b/shopify-brochure2-assets/b23d58f2bc9a0a5cd4b2c8ce973f5080.svg), linear-gradient(146deg, #D0F224 -9.16%, #79DFFF 100%)',

  // /first-sale
  'first-sale-conversion':
    'linear-gradient(to right top, #85fa92 40%, #98f688 44%, #a9f180 53%, #b7ed7a 62%, #d2e272 71%, #e7d771 80%, #f6cd77 89%, #ffc382 100%)',
  'first-sale-outcomes':
    'linear-gradient(180deg, rgba(255, 185, 109, 0.25) 3.34%, rgba(255, 185, 109, 0.25) 85.46%, rgba(255, 195, 130, 0) 100%)',

  // /shipping/carriers routes
  'shipping-carriers-hero':
    'linear-gradient(136.57deg, #000000 -40.83%, #000000 37.42%, #421046 63.16%, #79DFFF 116.95%);',
  'shipping-carriers-hero-next':
    'linear-gradient(96deg, rgba(71, 157, 156, 0.80) -14.42%, rgba(121, 223, 255, 0.80) 25.8%, rgba(75, 254, 133, 0.64) 67.38%, rgba(252, 243, 176, 0.80) 104.88%)',
  'shipping-carriers-rates':
    'linear-gradient(96deg, rgba(193, 251, 212, 0.80) 29.24%, rgba(75, 254, 133, 0.48) 54.65%, rgba(121, 223, 255, 0.80) 86.79%)',
  'shipping-carriers-rates-next':
    'linear-gradient(96deg, rgba(193, 251, 212, 0.80) 29.24%, rgba(75, 254, 133, 0.48) 54.65%, rgba(121, 223, 255, 0.80) 86.79%)',
  'shipping-carriers-footer':
    'linear-gradient(96deg, rgba(252, 243, 176, 0.80) -6.54%, rgba(193, 251, 212, 0.80) 29.21%, rgba(121, 223, 255, 0.80) 68.06%, rgba(30, 115, 114, 0.80) 100.11%)',
  'shipping-carriers-canada-post-hero':
    'linear-gradient(119.43deg, #000000 46%, #46034D 77.16%, #79DFFF 112.82%);',

  // /products
  'products-hero': 'linear-gradient(to bottom right, #e5fbba, #79dfff)',
  'products-conversion': 'linear-gradient(to bottom right, #e5fbba, #79dfff)',

  // /fulfillment
  'fulfillment-hero':
    'linear-gradient(277.04deg, #d7d3fe 16.82%, #c1fbd4 56.59%)',
  'fulfillment-pricing-product-example':
    'radial-gradient(50% 50% at 50% 50%, rgba(18, 4, 138, .6) 0%, rgba(18, 4, 138, 0) 100%)',
  'fulfillment-calculator-active-sample-product-image':
    'linear-gradient(22.82deg, #F3F1FF 63.28%, rgba(255, 255, 255, 0) 153.92%)',
  'fulfillment-promotion':
    'linear-gradient(105.44deg, #C1FBD4 25%, #D7D3FE 38%)',
  'fulfillment-promotion-sm':
    'linear-gradient(105.44deg, #C1FBD4 40%, #D7D3FE 73%)',
  'fulfillment-faq':
    'linear-gradient(165deg, rgba(215,211,254,1) 0%, rgba(193,251,212,1) 26%, rgba(255,255,255,0) 41%), linear-gradient(195deg, rgba(193,251,212,1) 0%, rgba(255,255,255,0) 35%)',

  // /logistics
  'logistics-conversion-button':
    'linear-gradient(133.83deg, #D0F224 25.51%, #AAFFC5 108.96%)',
  'logistics-conversion-button-hover': 'linear-gradient(#ffffff, #ffffff)',

  // /buy-button
  'buy-button-hero':
    'linear-gradient(107.3deg, #79dfff 1.69%, #ab8eff 105.13%)',
  'buy-button-hero-900':
    'linear-gradient(170deg, rgba(0, 0, 0, 0) 70%, #7d90e8), linear-gradient(143.92deg, #79dfff 34.39%, #ab8eff 89.64%)',
  'buy-button-card': 'linear-gradient(135deg, #00ff75 50%, #5fd1be, #b4a7ff)',

  // /shipping/dhl-express routes
  'shipping-carriers-dhl-hero':
    'linear-gradient(109.71deg, #000000 52.3%, #46034D 80.26%, #79DFFF 112.25%);',

  // /shipping/sendle routes
  'shipping-carriers-sendle-hero':
    'linear-gradient(119.43deg, #000000 46%, #46034D 77.16%, #79DFFF 112.82%)',

  // /shipping/usps routes
  'shipping-carriers-usps-hero':
    'linear-gradient(119.43deg, #000000 46%, #46034D 77.16%, #79DFFF 112.82%);',

  // /shipping/ups routes
  'shipping-carriers-ups-hero':
    'linear-gradient(109.71deg, #000000 52.3%, #46034D 80.26%, #79DFFF 112.25%);',

  // /free-triol/creators
  'free-trial-creators-product-demo':
    'linear-gradient(228.5deg, #DBF7FF 4.29%, #F7FFF0 70.04%)',

  // /logistics
  'logistics-hero-background': `url('https://cdn.shopify.com/shopifycloud/brochure2/production/images/logistics/hero-background.svg')`,
  'logistics-hero-headline':
    'linear-gradient(105.44deg, #D0F224 10.28%, #7ADFFF 62.56%)',

  // /audiences
  'audiences-outcome':
    'linear-gradient(95.63deg, #7CE0FF -3.24%, #D0F224 113.5%);',
  'audiences-sectionHeader':
    'linear-gradient(92.09deg, #79DFFF 8.56%, #D0F224 114.22%);',
  'audiences-faq':
    'linear-gradient(119.78deg, #D0F224 -19.57%, #7CE0FF 84.48%);',

  // careers
  'gradient-background': "url('/img-assets/gradient-background.jpg')",
  'careers-arrow':
    "url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2224%22%20height%3D%2247%22%20viewBox%3D%220%200%2024%2047%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M10.9393%2046.0607C11.5251%2046.6464%2012.4749%2046.6464%2013.0607%2046.0607L22.6066%2036.5147C23.1924%2035.9289%2023.1924%2034.9792%2022.6066%2034.3934C22.0208%2033.8076%2021.0711%2033.8076%2020.4853%2034.3934L12%2042.8787L3.51472%2034.3934C2.92893%2033.8076%201.97918%2033.8076%201.3934%2034.3934C0.80761%2034.9792%200.80761%2035.9289%201.3934%2036.5147L10.9393%2046.0607ZM10.5%20-6.55671e-08L10.5%2045L13.5%2045L13.5%206.55671e-08L10.5%20-6.55671e-08Z%22%20fill%3D%22black%22%2F%3E%0A%3C%2Fsvg%3E%0A')",
  'close-icon':
    "url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2044%2044%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3Ecircle%2C%20ellipse%2C%20line%2C%20path%2C%20polygon%2C%20polyline%2C%20rect%2C%20text%20%7B%20fill%3A%20rgb%280%2C%200%2C%200%29%20%21important%3B%20%7D%3C%2Fstyle%3E%3Cpath%20d%3D%22M39.196%2043.3L1.154%205.256l3.89-3.89%2038.04%2038.043z%22%2F%3E%3Cpath%20d%3D%22M.54%2039.413L38.58%201.37l3.89%203.89L4.428%2043.302z%22%2F%3E%3C%2Fsvg%3E')",

  // /bill-pay
  'bill-pay-shared':
    'linear-gradient(144.1deg, #FFFFFF 43.56%, #E8FDC0 108.45%);',
  'bill-pay-outcomes':
    'radial-gradient(137.93% 48.07% at 0% 48.9%, #E8FDC0 0%,rgba(255,255,255,0) 75%);',
  'bill-pay-conversion':
    'linear-gradient(182.81deg, #FFFFFF 42.33%, #EFFEC9 99.47%);',

  // Solid separated background
  'separate-top':
    'linear-gradient(var(--tw-gradient-from) 30%, var(--tw-gradient-to) 30%)',
  'separate-middle':
    'linear-gradient(var(--tw-gradient-from) 50%, var(--tw-gradient-to) 50%)',
  'separate-bottom':
    'linear-gradient(var(--tw-gradient-from) 70%, var(--tw-gradient-to) 70%)',

  // /fraud-control
  'fraud-control-hero':
    'linear-gradient(359.33deg, #E5FBBA -5.59%, rgba(229, 251, 186, 0) 96.05%);',
  'fraud-control-conversion':
    'linear-gradient(1.17deg, #E5FBBA 1.06%, rgba(229, 251, 186, 0) 99.06%)',

  // quickbooks pos
  'qb-discover-conversion':
    'linear-gradient(276.8deg, #C1FBD4 1.89%, #C7EDEC 100.01%)',

  // /marketplace-kit
  'marketplace-kit-gradient':
    'linear-gradient(100deg, #4BFE85 -10%, #28EFCC 40%, #F8FD23 100%)',

  // Capital BFCM
  'capital-bfcm-conversion-bg-image':
    "url('https://cdn.shopify.com/b/shopify-brochure2-assets/99d3f94e571d23cbee123210820db7dc.svg')",

  // Capital BFCM
  'capital-bfcm-hero-bg-image':
    "url('https://cdn.shopify.com/b/shopify-brochure2-assets/15460171bde502dc49c4467c7911134f.svg')",

  // bng
  'bng-results-button-gradient':
    'linear-gradient(121.04deg, #f6a179 15.69%, #865cff 100%)',
  'bng-results-button-preview-gradient':
    'linear-gradient(45deg, #865cff 0%, #f6a179 100%)',

  // marketplace-connect
  'marketplace-connect-hero':
    'linear-gradient(145deg, #79DFFF 0%, #4BFE85 100%)',

  // markets next
  'markets-next-hero':
    'linear-gradient(168deg, #FCDECF 0%, rgba(254, 246, 241, 0) 30%)',
  'markets-next-logos':
    'linear-gradient(360deg, rgba(252, 222, 207, 0.40) 0%, rgba(255, 255, 255, 0.00) 80.29%)',
  'markets-next-platform':
    'linear-gradient(0deg, rgba(246, 161, 121, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(180deg, rgba(252, 222, 207, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%)',
  'markets-next-sidebyside':
    'linear-gradient(180deg, rgba(252, 222, 207, 0.10) 0%, rgba(252, 222, 207, 0.50) 42.71%, rgba(200, 238, 237, 0.70) 100%)',
  // Temp.
  // 'markets-next-sidebyside2':
  //   'linear-gradient(186deg, rgba(200, 238, 237, 0.00) 0%, rgba(200, 238, 237, 0.70) 53.13%, rgba(200, 238, 237, 0.00) 90%)',
  'markets-next-sidebyside2':
    'linear-gradient(177deg, rgba(255, 255, 255, 1) 0%, rgba(200, 238, 237, .7) 53.13%, rgba(200, 238, 237, 0) 90%)',
  'markets-next-conversion':
    'linear-gradient(167deg, #FCDECF 0%, #C7EDEC 100%)',
  // markets-pro
  'markets-pro-sidebyside':
    'linear-gradient(189deg, rgba(255, 255, 255, 0.00) 0%, rgba(200, 238, 237, 0.50) 53.13%, rgba(255, 255, 255, 0.00) 60%)',
  'markets-panels-kale': 'rgba(199, 237, 236, 0.25)',
  // Markets Next
  'markets-next-cards':
    "url('https://cdn.shopify.com/b/shopify-brochure2-assets/f68efba1efddbf984d7e3b44683f7004.svg')",
  'markets-next-pro-info-mobile':
    'linear-gradient(180deg, rgba(199, 237, 236, 0.50) 0%, rgba(255, 255, 255, 0.00) 10%)',
  'markets-next-pro-info':
    "url('https://cdn.shopify.com/b/shopify-brochure2-assets/267ce94a5046bd625250a2d2e2246672.svg')",
  'markets-next-info-mobile':
    'linear-gradient(180deg, #FCDECF 0%, rgba(255, 255, 255, 0.00) 10%)',
  'markets-next-info':
    "url('https://cdn.shopify.com/b/shopify-brochure2-assets/4660eef44c20bd068ff6baf498061d4c.svg')",
  'markets-pricing-conversion':
    "url('https://cdn.shopify.com/b/shopify-brochure2-assets/e0e6dfd89f7d7bf519a710ef25b1df28.webp')",
  'markets-next-conversion2':
    'linear-gradient(145deg, #FCDECF -2.44%, #C7EDEC 106.81%)',

  // tiktok
  'tiktok-simple-gradient':
    'linear-gradient(180deg, rgba(93, 239, 231, 0.30) 0%, rgba(93, 239, 231, 0.00) 100%);',

  // retail migration bundle
  'retail-migration-bundle-text':
    'linear-gradient(127deg, #42D76C 32.36%, #55ACC8 100%)',
  'retail-migration-bundle-conversion':
    'linear-gradient(114deg, #BEFACF -1.58%, #8CE1FC 110.4%)',

  // compare
  'compare-conversion-form':
    'linear-gradient(120.58deg, #E5FBBA 0%, #79DFFF 122.65%)',

  // affiliates
  'affiliates-hero': `linear-gradient(126.51deg, #cec9f8 32.36%, #4bfe85 100%),
    linear-gradient(126.51deg, #79dfff 32.36%, #4bfe85 100%)`,
  'affiliates-earn-section':
    'linear-gradient(0deg, #E9F1F8 3.97%, rgba(233, 241, 248, 0.00) 86.68%);',

  // sustainability
  'climate-report-gradient':
    'linear-gradient(120deg, rgb(92, 189, 254), rgb(27, 159, 255), rgb(96, 212, 255), rgb(27, 159, 255), rgb(92, 189, 254))',
  'enginneering-light':
    "url('https://cdn.shopify.com/b/shopify-brochure2-assets/7dc91569c8533451b7bb4f8a3ee1ad55.png')",
  'enginneering-dark':
    "url('https://cdn.shopify.com/b/shopify-brochure2-assets/9b070dc8edbc4fcf7af47c900d85031a.png')",
};
