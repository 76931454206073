export default {
  shopifysans: ['ShopifySans', 'Helvetica', 'Arial', 'sans-serif'],
  ibmmono: ['IBMPlexMono', 'monospace'],
  polysans: ['PolySans', 'Helvetica', 'Arial', 'sans-serif'],
  trap: ['Trap', 'PolySans', 'Helvetica', 'Arial', 'sans-serif'],
  featuredisplay: ['FeatureDisplay', 'Helvetica', 'Arial', 'sans-serif'],
  courier: ['Courier New', 'Courier', 'monospace'],
  goodsans: ['GoodSans', 'Helvetica', 'Arial', 'sans-serif'],
  pathwaygothicone: ['PathwayGothicOne'],
  graphik: ['GraphikCondensed', 'Helvetica', 'Arial', 'sans-serif'],
  aktivgroteskextended: ['aktiv-grotesk-extended', 'ShopifySans', 'sans-serif'],
  inter: ['Inter', 'Helvetica', 'Arial', 'sans-serif'],
  inputsans: ['InputSans', 'monospace'],
  gtsuperdisplay: [
    'GTSuperDisplay',
    'FeatureDisplay',
    'Helvetica',
    'Arial',
    'serif',
  ],
  gtsupertext: [
    'GTSuperText',
    'GTSuperDisplay',
    'FeatureDisplay',
    'Helvetica',
    'serif',
  ],
  gtsupertextbook: [
    'GTSuperTextBook',
    'GTSuperDisplay',
    'FeatureDisplay',
    'Helvetica',
    'serif',
  ],
  naturalmarker: ['NaturalMarker', 'Arial', 'sans-serif'],
  naturalmarkeritalic: ['NaturalMarkerItalic', 'Arial', 'sans-serif'],
  monumentgrotesk: ['MonumentGrotesk', 'sans-serif'],
  monumentgroteskmedium: ['MonumentGroteskMedium', 'sans-serif'],
  monumentgroteskbold: ['MonumentGroteskBold', 'sans-serif'],
  monumentgroteskmono: ['MonumentGroteskMono', 'sans-serif'],

  /* Component Fonts */
  /* ======================================== */
  'button-font': ['ShopifySans', 'Helvetica', 'Arial', 'sans-serif'],
};
