import type {LazyExoticComponent, SVGProps} from 'react';
import {lazy} from 'react';

import {IconEnum} from '@/enums';

const svgIconMappings: {
  [key in IconEnum]?: LazyExoticComponent<
    (props: SVGProps<SVGSVGElement>) => JSX.Element
  >;
} = {
  [IconEnum.Apps]: lazy(() => import('./icons/Apps')),
  [IconEnum.Arrow]: lazy(() => import('./icons/Arrow')),
  [IconEnum.Bag]: lazy(() => import('./icons/Bag')),
  [IconEnum.BlogPost]: lazy(() => import('./icons/BlogPost')),
  [IconEnum.Boost]: lazy(() => import('./icons/Boost')),
  [IconEnum.BuyOnline]: lazy(() => import('./icons/BuyOnline')),
  [IconEnum.Champion]: lazy(() => import('./icons/Champion')),
  [IconEnum.Check]: lazy(() => import('./icons/Check')),
  [IconEnum.Checkmark]: lazy(() => import('./icons/Checkmark')),
  [IconEnum.CheckmarkCircle]: lazy(() => import('./icons/CheckmarkCircle')),
  [IconEnum.Close]: lazy(() => import('./icons/Close')),
  [IconEnum.Code]: lazy(() => import('./icons/Code')),
  [IconEnum.CollectEmail]: lazy(() => import('./icons/CollectEmail')),
  [IconEnum.Collapse]: lazy(() => import('./icons/Collapse')),
  [IconEnum.ComputerTick]: lazy(() => import('./icons/ComputerTick')),
  [IconEnum.CreditCard]: lazy(() => import('./icons/CreditCard')),
  [IconEnum.CustomerExperience]: lazy(
    () => import('./icons/CustomerExperience'),
  ),
  [IconEnum.CustomerSupport]: lazy(() => import('./icons/CustomerSupport')),
  [IconEnum.Design]: lazy(() => import('./icons/Design')),
  [IconEnum.Detect]: lazy(() => import('./icons/Detect')),
  [IconEnum.Devices]: lazy(() => import('./icons/Devices')),
  [IconEnum.DiagonalArrow]: lazy(() => import('./icons/DiagonalArrow')),
  [IconEnum.Discount]: lazy(() => import('./icons/Discount')),
  [IconEnum.Domain]: lazy(() => import('./icons/Domain')),
  [IconEnum.DragAndDrop]: lazy(() => import('./icons/DragAndDrop')),
  [IconEnum.Expand]: lazy(() => import('./icons/Expand')),
  [IconEnum.Filters]: lazy(() => import('./icons/Filters')),
  [IconEnum.FlexibleShopping]: lazy(() => import('./icons/FlexibleShopping')),
  [IconEnum.FlexibleTime]: lazy(() => import('./icons/FlexibleTime')),
  [IconEnum.Gift]: lazy(() => import('./icons/Gift')),
  [IconEnum.Global]: lazy(() => import('./icons/Global')),
  [IconEnum.GlobalCurrency]: lazy(() => import('./icons/GlobalCurrency')),
  [IconEnum.Group]: lazy(() => import('./icons/Group')),
  [IconEnum.Guarantee]: lazy(() => import('./icons/Guarantee')),
  [IconEnum.Information]: lazy(() => import('./icons/Information')),
  [IconEnum.InventorySynced]: lazy(() => import('./icons/InventorySynced')),
  [IconEnum.Legal]: lazy(() => import('./icons/Legal')),
  [IconEnum.Location]: lazy(() => import('./icons/Location')),
  [IconEnum.Mail]: lazy(() => import('./icons/Mail')),
  [IconEnum.Marketing]: lazy(() => import('./icons/Marketing')),
  [IconEnum.MaximumGauge]: lazy(() => import('./icons/MaximumGauge')),
  [IconEnum.Messages]: lazy(() => import('./icons/Messages')),
  [IconEnum.Money]: lazy(() => import('./icons/Money')),
  [IconEnum.Next]: lazy(() => import('./icons/Next')),
  [IconEnum.OnlineStore]: lazy(() => import('./icons/OnlineStore')),
  [IconEnum.Packaging]: lazy(() => import('./icons/Packaging')),
  [IconEnum.Pause]: lazy(() => import('./icons/Pause')),
  [IconEnum.Phone]: lazy(() => import('./icons/Phone')),
  [IconEnum.PhoneTick]: lazy(() => import('./icons/PhoneTick')),
  [IconEnum.Play]: lazy(() => import('./icons/Play')),
  [IconEnum.PosSwipe]: lazy(() => import('./icons/PosSwipe')),
  [IconEnum.PosTap]: lazy(() => import('./icons/PosTap')),
  [IconEnum.PosTick]: lazy(() => import('./icons/PosTick')),
  [IconEnum.Previous]: lazy(() => import('./icons/Previous')),
  [IconEnum.Product]: lazy(() => import('./icons/Product')),
  [IconEnum.Quick]: lazy(() => import('./icons/Quick')),
  [IconEnum.Read]: lazy(() => import('./icons/Read')),
  [IconEnum.RealTime]: lazy(() => import('./icons/RealTime')),
  [IconEnum.Replay]: lazy(() => import('./icons/Replay')),
  [IconEnum.Report]: lazy(() => import('./icons/Report')),
  [IconEnum.Return]: lazy(() => import('./icons/Return')),
  [IconEnum.SalesChannels]: lazy(() => import('./icons/SalesChannels')),
  [IconEnum.SalesUp]: lazy(() => import('./icons/SalesUp')),
  [IconEnum.SaveTime]: lazy(() => import('./icons/SaveTime')),
  [IconEnum.Search]: lazy(() => import('./icons/Search')),
  [IconEnum.Secure]: lazy(() => import('./icons/Secure')),
  [IconEnum.Seo]: lazy(() => import('./icons/Seo')),
  [IconEnum.Shipping]: lazy(() => import('./icons/Shipping')),
  [IconEnum.Social]: lazy(() => import('./icons/Social')),
  [IconEnum.Star]: lazy(() => import('./icons/Star')),
  [IconEnum.Stop]: lazy(() => import('./icons/Stop')),
  [IconEnum.Store]: lazy(() => import('./icons/Store')),
  [IconEnum.Success]: lazy(() => import('./icons/Success')),
  [IconEnum.Tools]: lazy(() => import('./icons/Tools')),
  [IconEnum.Unlimited]: lazy(() => import('./icons/Unlimited')),
  [IconEnum.Uptime]: lazy(() => import('./icons/Uptime')),
  [IconEnum.UserProfile]: lazy(() => import('./icons/UserProfile')),
  [IconEnum.Visible]: lazy(() => import('./icons/Visible')),
  [IconEnum.Wallet]: lazy(() => import('./icons/Wallet')),
};

export default svgIconMappings;
